import React from 'react';
import styled from '@emotion/styled';
import { Article } from '../core/interfaces';
import { hash } from '../core/utils';
import VPeye from '../assets/images/vp-eye.png';
import FaviconDefault from '../assets/images/favicon-default.png';

const Container = styled.div`
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  height: 72px;
  padding: 12px 16px 10px 16px;
  margin-bottom: 8px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const TitleStyled = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 2.4em;
  line-height: 1.2em;
`;

const ArticleIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 6px;
`;

const FooterContainer = styled.div`
  display: flex;
  margin-top: 5px;
`;

const UrlStyled = styled.div`
  color: #929292;
  font-size: 10px;
  line-height: 14px;
`;

const ThumbnailStyled = styled.img`
  margin-left: 18px;
  height: auto;
  width: 77px;
  max-width: 77px;
`;

interface ArticleSectionProps {
  article: Article;
}

export const ArticleSection: React.FunctionComponent<ArticleSectionProps> = (
  props: ArticleSectionProps
) => {
  const { logo, icon, name, domain, url } = props.article;
  const goToArticle = (articleUrl: string): void => {
    // here we should save the articleUrl to local storage
    console.log('articleUrl', articleUrl);
  };

  return (
    <a href={url}>
      <Container id={hash(url)} onClick={(): void => goToArticle(url)}>
        <BodyContainer>
          <TitleStyled> {name}</TitleStyled>
          <FooterContainer>
            <ArticleIcon src={`${icon || FaviconDefault}`} />
            <UrlStyled> {domain || name}</UrlStyled>
          </FooterContainer>
        </BodyContainer>
        <ThumbnailStyled src={logo || VPeye} />
      </Container>
    </a>
  );
};
