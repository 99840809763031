import React from 'react';

export interface RouteItem {
  name: string;
  path: string;
  key: string;
  zendeskWidget: boolean;
  component: React.ReactType;
}

export interface ReactProps {
  children?: React.ReactNode;
  className?: string;
}

export interface FAQInfo {
  title: string;
  descritpion: JSX.Element;
}

export interface Article {
  image: string;
  logo: string;
  domain: string | undefined;
  name: string;
  icon: string;
  url: string;
  category?: string;
}

export enum DownloadIconColor {
  black,
  while,
}

export enum StudyType {
  Display = 'display',
  OLV = 'olv',
}

export enum BrandTypeParam {
  brandStudy = 'brand-study',
  dataCollection = 'data-collection',
}

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
}

export interface IpData {
  city: string;
  continent_code: string;
  continent_name: string;
  count: string;
  country_code: string;
  country_name: string;
}

export enum ErrorType {
  studyEnded = 'studyEnded',
  studySetup = 'studySetup',
  taskFinished = 'taskFinished',
  generalError = 'generalError',
}
