import { Article } from './interfaces';

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const addZendeskWidget = (): void => {
  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.src =
    'https://static.zdassets.com/ekr/snippet.js?key=058d70d2-1415-4d8a-951a-90e5ab5bab90';
  script.async = true;
  document.head.appendChild(script);
};

export const isYoutubeStudy = (articles: Article[]): boolean => {
  if (articles.length !== 1) {
    return false;
  }

  try {
    const article = articles[0];
    const { hostname } = new URL(article.url);
    return hostname.endsWith('youtube.com');
  } catch (error) {
    return false;
  }
};

export const hash = (s: string): string => {
  /* Simple hash function. */
  let a = 1,
    c = 0,
    h,
    o;
  if (s) {
    a = 0;
    /*jshint plusplus:false bitwise:false*/
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = ((a << 6) & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = c !== 0 ? a ^ (c >> 21) : a;
    }
  }
  return String(a);
};

// the order of this array is important as
// we use it to prioritise the value of ext
const platformUserIdKeys: string[] = [
  'ext', // kantar
  'state', // kantar
  'user_id', // mturk && click-worker
  'workerId', // mturk premium
];

export const getStudyIdFromParams = (params: URLSearchParams): string => {
  let studyIdParam = params.get('study_id') || '';
  studyIdParam = decodeURIComponent(studyIdParam);
  const studyIds = studyIdParam.split(',');
  const getRandomChoice = (array: string[]): string =>
    array[Math.floor(Math.random() * array.length)];
  // If multiple studyids included within the invite URL, then
  // randomly pick one
  return studyIds.length === 1 ? studyIds[0] : getRandomChoice(studyIds);
};

export const getUserIdFromParams = (params: URLSearchParams): string => {
  const key = platformUserIdKeys.find((key: string): string | null =>
    params.get(key)
  );
  return (key && params.get(key)) ?? '';
};
