import React from 'react';
import { Panel } from '../../composites/panel';
import styled from '@emotion/styled';
import { DownloadIOSApp } from '../../components/downloadIOSApp';
import { Footer } from '../../composites/footer';
import { DownloadIconColor } from '../../core/interfaces';

const ParagraphStyled = styled.p`
  text-align: center;
  margin-bottom: 25px;
`;

const HighlightSpan = styled.span`
  color: #ff3926;
`;

const DownloadContainer = styled.div`
  display: block;
  margin: 15px auto;
  width: 104px;
`;

export const Home: React.FunctionComponent<{}> = () => {
  return (
    <React.Fragment>
      <Panel>
        <h2>
          We’re on a mission to understand <br />
          how people browse the web.
        </h2>
        <br />
        <ParagraphStyled>
          We want to know: Where do people tend to look when they’re browsing?
          What kind of content{' '}
          <HighlightSpan>grabs their attention</HighlightSpan>? <br />
          And for how long?
        </ParagraphStyled>
        <ParagraphStyled>
          It’s not easy to infer these insights from a survey or through web
          analytics. That’s why we created the Vision Project - a browser which
          uses the front-facing camera to predict where you are looking on the
          screen while you browse.
        </ParagraphStyled>
        <ParagraphStyled>
          Participants opt in and earn cash for taking part in these anonymous
          user research sessions. To date, we have had over{' '}
          <HighlightSpan>7000 participants</HighlightSpan> through the platform
          and it’s growing every day. Data collected from our research is shared
          at an aggregate level only - revealing trends in user browsing
          behaviour to help guide the design and development of better user
          experiences.
        </ParagraphStyled>
        <ParagraphStyled>
          If you are a user looking for assistance, please contact us via the
          Support link on this page.
        </ParagraphStyled>
      </Panel>
      <DownloadContainer>
        <DownloadIOSApp color={DownloadIconColor.while} />
      </DownloadContainer>
      <Footer />
    </React.Fragment>
  );
};
