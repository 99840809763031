import React from 'react';
import styled from '@emotion/styled';
import Robot404 from '../../assets/images/robot-404.png';

const Container = styled.div`
  font-style: normal;
  margin: 108px 22px 0px 22px;
`;

const Panel = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: 51px 32px 0px 32px;
  margin-bottom: 22px;
`;

const RobotStyled = styled.img`
  display: block;
  margin-top: 39px;
`;

export const IOSBrowser404: React.FunctionComponent = () => {
  return (
    <Container>
      <Panel>
        <span>
          We can’t find the page you’re looking for. Try a different site or go
          back to home.
        </span>
        <RobotStyled src={Robot404} />
      </Panel>
    </Container>
  );
};
