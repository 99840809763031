import { API_BASE_URL } from './../config';
import { Article, BrandTypeParam } from './../core/interfaces';
import httpService from './httpService';
import { setErrorAnalytics } from '../services/analyticService';

const getFeedFromGlobalVar = (): Promise<Article[]> => {
  let data: Article[] = [];
  try {
    const jsonFeed = decodeURIComponent(window['pxyzFeed']);
    data = JSON.parse(jsonFeed);
  } catch (ex) {
    console.log('Feed not found in global var');
  }
  return Promise.resolve(data);
};

const getPath = (
  studyType: string,
  feedId: string,
  continent: string | null
): string => {
  const defaultContinent = 'Oceania';

  let path = '';
  switch (studyType) {
    case BrandTypeParam.brandStudy:
      path = `feed/${feedId}/${continent || defaultContinent}`;
      break;
    case BrandTypeParam.dataCollection:
      path = `customFeed/${feedId}`;
      break;
    default:
      console.error('type study not found');
      break;
  }
  return path;
};

export const getFeed = async (
  type: string,
  id: string,
  continent: string | null
): Promise<Article[]> => {
  let result: Article[] = [];
  try {
    const feed = await getFeedFromGlobalVar();
    if (!feed || feed.length === 0) {
      const endpoint = getPath(type, id, continent);
      const urlPath = `siteDirectory/${endpoint}`;
      const encodeUrl = encodeURI(`${API_BASE_URL}${urlPath}`);
      const response = await httpService.get<[Article]>(encodeUrl);
      result = response.data;
    } else {
      result = feed;
      console.log('loading feed from global var');
    }
  } catch (error) {
    setErrorAnalytics({
      error: 'feed',
      message: error.response,
      page: 'iosHomePage',
    });
    console.error(`Unable to fetch feed id: ${id}`);
  }
  return result;
};
