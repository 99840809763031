import React from 'react';
import styled from '@emotion/styled';
import { ButtonType } from '../core/interfaces';

interface ButtonProps {
  text: string | JSX.Element;
  type: ButtonType;
  clickHandler?(): void;
}

const ButtonStyled = styled.div<ButtonProps>`
  background-color: ${({ type }): string =>
    type === ButtonType.primary ? '#ff3926' : '#A6A6A6'};
  color: white;
  line-height: 38px;
  display: inline-block;
  padding: 0 20px;
  border-radius: 5px;
  margin: 5px;
  width: 90%;
  font-size: 15px;
`;

export const Button: React.FunctionComponent<ButtonProps> = (
  props: ButtonProps
) => {
  const { text, type, clickHandler } = props;
  return (
    <ButtonStyled {...{ type, text }} onClick={clickHandler}>
      {text}
    </ButtonStyled>
  );
};
