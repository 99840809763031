import styled from '@emotion/styled';
import React from 'react';
import Robot from '../assets/images/robot.png';

const RobotStyled = styled.img`
  position: absolute;
  z-index: -1;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  top: -50px;
  animation-name: robot_hover;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes robot_hover {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    25% {
      transform: translate(-10px, 5px) rotate(-5deg);
    }
    50% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    75% {
      transform: translate(10px, 5px) rotate(5deg);
    }
    100% {
      transform: translate(0px, 0px) rotate(0deg);
    }
  }

  @media only screen and (max-width: 600px) {
    width: 300px;
    margin-left: -150px;
  }
`;

export const AnimatedRobot: React.FunctionComponent = () => {
  return <RobotStyled decoding="sync" src={Robot} />;
};
