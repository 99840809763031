import React from 'react';
import styled from '@emotion/styled';
import { Article } from '../core/interfaces';
import { capitalizeFirstLetter, hash } from '../core/utils';
import articlePlacholder from '../assets/images/article-placeholder.svg';
import { getArticleVisited, setArticleVisited } from '../core/localStorage';

interface ArticleNameProps {
  url: string;
}

const Container = styled.div`
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  height: 72px;
  padding: 12px 16px 10px 16px;
  margin-bottom: 8px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TitleStyled = styled.div<ArticleNameProps>`
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: ${({ url }): string =>
    getArticleVisited(url) ? '#FE3928' : '#1D1D1F'};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 2.4em;
  line-height: 1.2em;
`;

const FooterContainer = styled.div`
  display: flex;
  margin-top: 5px;
`;

const CategoryStyled = styled.div`
  color: #fe3928;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 2px;
`;

const ThumbnailStyled = styled.img`
  margin-right: 16px;
  height: 47px;
  width: 77px;
  max-width: 77px;
`;

interface ArticleSectionProps {
  article: Article;
}

export const ArticleContainer: React.FunctionComponent<ArticleSectionProps> = (
  props: ArticleSectionProps
) => {
  const { logo, name, url, category } = props.article;
  const goToArticle = (articleUrl: string): void => {
    setArticleVisited(articleUrl);
  };

  return (
    <a href={url}>
      <Container id={hash(url)} onClick={(): void => goToArticle(url)}>
        <ThumbnailStyled src={logo || articlePlacholder} />
        <BodyContainer>
          <TitleStyled url={url}>{capitalizeFirstLetter(name)}</TitleStyled>
          <FooterContainer>
            <CategoryStyled>
              {category && capitalizeFirstLetter(category)}
            </CategoryStyled>
          </FooterContainer>
        </BodyContainer>
      </Container>
    </a>
  );
};
