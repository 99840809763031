import React from 'react';
import { FAQInfo } from './interfaces';

export const FAQContent: FAQInfo[] = [
  {
    title: 'How do I log in to the app and access my task?',
    descritpion: (
      <React.Fragment>
        <p>
          After downloading the app, you will be asked to log in using a
          temporary User ID and password.
        </p>
        <p>
          To find your User ID and password, click the orange ‘Generate Access
          Code’ button in Step 1 above. If you are having trouble logging in,
          reach out to our support team using the ‘?’ icon on this page.
        </p>
      </React.Fragment>
    ),
  },
  {
    title: `I can't calibrate during the robot game, what do I do?`,
    descritpion: (
      <React.Fragment>
        <p>
          Our eye tracking technology is very sensitive to light and movement.
          Please ensure you are using the app in a well lit space and try to
          keep your head as still as possible through the process.
        </p>
        <p>Calibration tips can be found in the Game Over screen.</p>
      </React.Fragment>
    ),
  },
  {
    title: `The app froze while I was completing my task and I can no longer access it. What should I do?`,
    descritpion: (
      <p>
        If the app froze while you were in the middle of the task, try closing
        and re-opening it. If you are still experiencing issues, reach out to
        our support team using the ‘?’ icon on this page.
      </p>
    ),
  },
  {
    title: `I've completed my task, when will I receive payment?`,
    descritpion: (
      <p>
        For any questions about payment or task submission, please contact your
        task provider directly.
      </p>
    ),
  },
  {
    title: `What type of data are you collecting and what will you do with it?`,
    descritpion: (
      <React.Fragment>
        <p>
          We partner with brands, publishers and digital product developers to
          deliver market insights from eye-tracking research. The data we
          collect is completely anonymous and based on your interactions and
          behaviour within the app only.
        </p>
        <p>
          The app uses the front facing camera to see your eyes and a
          mathematical model to predict where you are looking on the screen (and
          where you are not). All image processing takes place on-device and no
          images are stored.
        </p>
        <p>
          For more details, read our terms of use, privacy policy and direct
          collection notice.
        </p>
      </React.Fragment>
    ),
  },
];
