import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import styled from '@emotion/styled';
import { Button } from '../../components/button';
import { ButtonType } from '../../core/interfaces';
import { onClickAnalytics } from '../../services/analyticService';

interface CopyToClipBoardButtonProps {
  text: string;
  successText?: string;
}

const SuccessTextStyled = styled.p`
  margin-top: 0;
`;
export const CopyToClipBoardButton: React.FunctionComponent<CopyToClipBoardButtonProps> = ({
  text,
  successText,
}: CopyToClipBoardButtonProps) => {
  const [isCopy, setIsCopy] = useState(false);
  return (
    <React.Fragment>
      <Button
        text={isCopy ? 'Copied!' : 'Copy login details'}
        type={isCopy ? ButtonType.secondary : ButtonType.primary}
        clickHandler={(): void => {
          onClickAnalytics({action: "copyToClipBoard", page: "generateLoginDetailsPage"});
          copy(text);
          setIsCopy(true);
        }}
      />
      {isCopy && <SuccessTextStyled>{successText}</SuccessTextStyled>}
    </React.Fragment>
  );
};
