import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const dotsAnimation = keyframes`
    0%, 12.5% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    37.5% {
        text-shadow: .5em 0;
    }
    50%, 100% {
        text-shadow: .5em 0, 1em 0;
    }
`;

const LoadingStyled = styled.div`
  text-align: center;
  &:after {
    content: '.';
    margin-left: 2px;
    animation: ${dotsAnimation} 1.5s steps(1, end) infinite;
  }
`;

interface LoadingProps {
  text?: string;
}

export const Loading: React.FunctionComponent<LoadingProps> = ({
  text,
}: LoadingProps) => {
  return <LoadingStyled> {text ? text : 'Loading'} </LoadingStyled>;

};
