import { IOSBrowser404 } from '../controllers/iosBrowser404/iosBrowser404';
import { Landing } from '../controllers/landing/landing';
import { ContentPolicy } from '../controllers/privacy/contentPolicy';
import { TermsOfUseAgreement } from '../controllers/privacy/termsOfUseAgreement';
import { PrivacyPolicy } from '../controllers/privacy/privacyPolicy';
import { DirectCollectionNotice } from '../controllers/privacy/directCollectionNotice';
import { Home } from '../controllers/home/home';
import { IOSBrowserHome } from '../controllers/iosBrowserHome/iosBrowserHome';

import { RouteItem } from './interfaces';
import { IOSYoutubeDemo } from '../controllers/iosYoutubeDemo/iosYoutubeDemo';

export const routes: RouteItem[] = [
  {
    name: 'IOS Youtube Demo',
    key: 'ios-youtube-demo',
    path: '/ios/youtube-demo',
    zendeskWidget: false,
    component: IOSYoutubeDemo,
  },
  {
    name: 'IOS Browser Home',
    key: 'ios-browser-home',
    path: '/ios/browser-home',
    zendeskWidget: false,
    component: IOSBrowserHome,
  },
  {
    name: 'IOS Browser 404',
    key: 'ios-browser-404',
    path: '/ios/404',
    zendeskWidget: false,
    component: IOSBrowser404,
  },
  {
    name: 'Direct Collection Notice',
    key: 'direct-collection-notice',
    path: '/direct-collection-notice',
    zendeskWidget: false,
    component: DirectCollectionNotice,
  },
  {
    name: 'Privacy Policy',
    key: 'privacy-policy',
    path: '/privacy-policy',
    zendeskWidget: false,
    component: PrivacyPolicy,
  },
  {
    name: 'Terms Of Use Agreement',
    key: 'terms-of-use-agreement',
    path: '/terms-of-use-agreement',
    zendeskWidget: false,
    component: TermsOfUseAgreement,
  },
  {
    name: 'Terms Of Use Agreement old', // This is a temp fix as the current version of the app is using this link
    key: 'terms-of-use-agreement-old',
    path: '/terms',
    zendeskWidget: false,
    component: TermsOfUseAgreement,
  },
  {
    name: 'Content Policy',
    key: 'content-policy',
    path: '/content-policy',
    zendeskWidget: false,
    component: ContentPolicy,
  },
  {
    name: 'Landing',
    key: 'landing',
    path: '/landing.html',
    zendeskWidget: true,
    component: Landing,
  },
  {
    name: 'Home',
    key: 'home',
    path: '/',
    zendeskWidget: true,
    component: Home,
  },
];
