import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ErrorType } from '../../core/interfaces';
import {
  setDisplayPageAnalytics,
  setDisplayViewAnalytics,
} from '../../services/analyticService';
import robot from '../../assets/images/robot-error.png';

const PanelStyled = styled.div`
  margin: 200px auto 20px auto;
  width: 600px;
  color: #212121;
  padding: 36px 55px;
  border-radius: 10px;
  height: 70%;
  min-height: 70%;

  @media only screen and (max-width: 600px) {
    width: auto;
    margin: 20px;
    padding: 36px 29px 54px 29px;
  }
`;

const Container = styled.div`
  background-color: white;
  width: 100%;
  color: #212121;
  border-radius: 10px;
  height: 70%;
  min-height: 70%;
`;

const Body = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  h2 {
    font-weight: bold;
    font-size: 30px;
    text-align: left;
    margin-top: 30px;
  }
`;

const Button = styled.div`
  background-color: white;
  color: #fe3928;
  text-align: center;
  border-radius: 6px;
  width: 100%;
  font-weight: bold;
  font-size: 22px;
  padding: 16px;
  margin-top: 40px;
`;

const RobotStyled = styled.img`
  display: block;
  margin-top: 39px;
  width: 100%;
  height: 60%;
  padding: 0px 55px;
`;

interface Props {
  error: ErrorType;
  clickHandler?(): void;
}

export const ErrorView: React.FunctionComponent<Props> = (props: Props) => {
  const { error, clickHandler } = props;

  const errorMessage = (
    title: string,
    message: string,
    note?: string
  ): React.ReactNode => {
    return (
      <React.Fragment>
        <h2>{title}</h2>
        <p>{message}</p>
        <p>{note}</p>
      </React.Fragment>
    );
  };

  const studyEnded = (): React.ReactNode => {
    return errorMessage(
      'Study ended',
      'Unfortunately, this study has ended and cannot be completed.'
    );
  };

  const studySetup = (): React.ReactNode => {
    return errorMessage(
      'Study set up error',
      'Unfortunately the study is not set up correctly and cannot be completed.',
      'For further assistance, contact us by clicking on the support link below.'
    );
  };

  const taskFinished = (): React.ReactNode => {
    return errorMessage(
      'Study unavailable',
      'It looks like you’ve completed this study before.',
      'For further assistance, contact us by clicking on the support link below.'
    );
  };

  const generalError = (): React.ReactNode => {
    return (
      <React.Fragment>
        {errorMessage(
          'An error occurred',
          'It appears to be a technical issue and we are investigating the cause. Meanwhile, please try one of the following options:'
        )}
        <ul>
          <li>Completely close the app</li>
          <li>
            Click on the task link you were provided again to bring up the
            Vision Project landing page in your browser
          </li>
          <li>See if you can generate a new access code</li>
          <li>Reopen the app and try again</li>
        </ul>
        <p>
          For further assistance, contact us by clicking on the support link
          below.
        </p>
      </React.Fragment>
    );
  };

  const displayContent = (): React.ReactNode => {
    switch (error) {
      case ErrorType.studyEnded:
        setDisplayViewAnalytics({
          view: 'studyEnded',
          page: 'errorPage',
        });
        return studyEnded();
      case ErrorType.studySetup:
        setDisplayViewAnalytics({
          view: 'studySetup',
          page: 'errorPage',
        });
        return studySetup();
      case ErrorType.taskFinished:
        setDisplayViewAnalytics({
          view: 'taskFinished',
          page: 'errorPage',
        });
        return taskFinished();
      case ErrorType.generalError:
      default:
        setDisplayViewAnalytics({
          view: 'generalError',
          page: 'errorPage',
        });
        return generalError();
    }
  };

  setDisplayPageAnalytics({ page: 'errorPage' });

  return (
    <React.Fragment>
      <PanelStyled>
        <Container>
          <Body>{displayContent()}</Body>
          <RobotStyled src={robot} />
        </Container>
        <Button onClick={clickHandler}>Back to welcome page</Button>
      </PanelStyled>
    </React.Fragment>
  );
};
