import React, { useState, useEffect, ReactNode } from 'react';

import styled from '@emotion/styled';
import { MobileSiteLogo } from '../../components/MobileSiteLogo';
import { Loading } from '../../components/loading';
import Demo1 from "../../assets/gif/demo1.gif";
import Demo2 from "../../assets/gif/demo2.gif";
import {onClickAnalytics, setDisplayPageAnalytics } from '../../services/analyticService';

const Footer = styled.footer`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: clear;
  color: white;
  text-align: center;
  padding: 30px 20px;
`;

const DemoTitle = styled.span`
  font-family: "Roboto";
  font-style: normal;
  margin: 0 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  text-align: center;
`;

const ButtonStyled = styled.div`
  display: block;
  background-color: white;
  color: #ff3926;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;

  border-radius: 5px;
  padding: 16px;
  margin-top: 30px;
  text-align: center;
`;

const RightLinkUnderlined = styled.a`
  display: block;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  text-decoration: underline;
  float: right;
`;

const RightButtonUnderlined = styled.div`
  display: block;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  text-decoration: underline;
  float: right;
`;

const LeftButtonUnderlined = styled.div`
  display: block;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  text-decoration: underline;
  float: left;
`;

const ImageStyled = styled.img`
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-height: 700px) {
    height: 350px;
  }

  height: 500px;
`;

const ImageStyled1 = styled.img`
  display: block;

  @media only screen and (max-height: 700px) {
    height: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-height: 700px) {
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.div`
  font-style: normal;
  color: white;
`;

const LogoContainer = styled.div`
  padding: 0 5%px;
  margin: 22px 0 7px 0;
`;

export const IOSYoutubeDemo: React.FunctionComponent = () => {
  const [isNextDemoPlaying, setIsNextDemoPlaying] = useState<boolean>(false);

  const YoutubeDemo1: React.FunctionComponent = () => {
    useEffect(() => {
      setDisplayPageAnalytics({page: "youtubeDemoPage1"});
    }, []);
    return (
      <>
        <ImageStyled src={Demo1} />
        <DemoTitle>
            Scroll through the Youtube feed or use the search bar to find a specific video.
        </DemoTitle>
        <Footer>
          <RightButtonUnderlined
            onClick={(): void => {
              onClickAnalytics({action: "nextDemoPage", page: "youtubeDemoPage1"});
              setIsNextDemoPlaying(true);
            }}
          >
            Next
          </RightButtonUnderlined>
        </Footer>
      </>
    );
  };

  const YoutubeDemo2: React.FunctionComponent = () => {
    useEffect(() => {
      setDisplayPageAnalytics({page: "youtubeDemoPage2"});
    }, []);
    return (
      <>
        <ImageStyled1 src={Demo2} />
        <DemoTitle>
            Clicking on a video will automatically take you into full screen mode to minimise distractions during the study. You can exit full screen mode any time.
        </DemoTitle>
        <Footer>
          <LeftButtonUnderlined
            onClick={(): void => {
              onClickAnalytics({action: "previousDemoPage", page: "youtubeDemoPage2"});
              setIsNextDemoPlaying(false);
            }}
          >
            Previous
          </LeftButtonUnderlined>
          <RightLinkUnderlined href="https://m.youtube.com" 
            onClick = { () => {
                onClickAnalytics({action: "continueToYoutube", page: "youtubeDemoPage2"});
              }
            }
          >
            Start Watching
          </RightLinkUnderlined>
        </Footer>
      </>
    );
  };

  useEffect(() => {
      setDisplayPageAnalytics({page: "youtubeDemoPage"});
  }, []);

  return (
    <Container>
      <LogoContainer>
        <MobileSiteLogo />
      </LogoContainer>
      <>{isNextDemoPlaying ? <YoutubeDemo2 /> : <YoutubeDemo1 />} </>
    </Container>
  );
};
