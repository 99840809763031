import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Panel } from '../../composites/panel';
import styled from '@emotion/styled';
import { FUNCTIONS_BASE_URL } from '../../config';
import { DownloadIOSApp } from '../../components/downloadIOSApp';
import httpService from '../../services/httpService';
import { InputBox } from '../../composites/inputBox';
import { Button } from '../../components/button';
import { ButtonType } from '../../core/interfaces';
import { CopyToClipBoardButton } from './copyToClipBoardButton';
import { Loading } from '../../components/loading';
import { FAQContent } from '../../core/faq';
import { FaqItem } from './faqItem';
import { Footer } from '../../composites/footer';
import {
  setErrorAnalytics,
  onClickAnalytics,
  setDisplayPageAnalytics,
  setDisplayViewAnalytics,
} from '../../services/analyticService';
import { ErrorView } from './error';
import { ErrorType } from '../../core/interfaces';

const AccessCodeContainer = styled.div`
  text-align: center;
`;

const MessageStyled = styled.div`
  color: #ff3926;
  padding-top: 10px;
  font-size: 13px;
`;

const NoteStyled = styled.div`
  color: black;
  padding-top: 10px;
  font-size: 13px;
`;

const ResetPasswordNoticeStyled = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: initial;
`;

interface Credentials {
  email: string;
  newUser: boolean;
  password: string;
}

enum View {
  generateCode = 'generateCode',
  displayCredentials = 'displayCredentials',
  taskStarted = 'taskStarted',
  error = 'error',
}

interface Props {
  userId: string;
  studyId: string;
}

export const MobileView: React.FunctionComponent<Props> = (props: Props) => {
  const [credentials, setCredential] = useState({} as Credentials);
  const [isGeneratedClicked, setIsGeneratedClicked] = useState(false);
  const [view, setView] = useState(View.generateCode);
  const [error, setError] = useState(ErrorType.generalError);

  const onGenerateAccessCode = (): void => {
    setIsGeneratedClicked(true);
    onClickAnalytics({
      action: 'generateLoginDetails',
      page: 'generateLoginDetailsPage',
    });

    const url = `${FUNCTIONS_BASE_URL}create-user?studyId=${props.studyId}&ext=${props.userId}`;
    httpService
      .get(encodeURI(url))
      .then((response) => {
        if (response.data) {
          const { status } = response.data;
          switch (status) {
            case 201:
              setCredential(response.data);
              setView(View.taskStarted);
              break;
            case 200:
              setCredential(response.data);
              setView(View.displayCredentials);
              break;
            default:
              setIsGeneratedClicked(false);
              setView(View.error);
              break;
          }
        } else {
          setIsGeneratedClicked(false);
          setView(View.error);
        }
      })
      .catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              setError(ErrorType.taskFinished);
              break;
            case 403:
              setError(ErrorType.studyEnded);
              break;
            case 404:
              setError(ErrorType.studySetup);
              break;
            default:
              setError(ErrorType.generalError);
              break;
          }
          setIsGeneratedClicked(false);
          setView(View.error);
        }
      });
  };

  const resetPasswordNotice = (): React.ReactNode => {
    const message = 'It looks like you have an active User ID.';
    setErrorAnalytics({
      error: 'generateLoginDetails',
      message: message,
      page: 'generateLoginDetailsPage',
    });
    return (
      <ResetPasswordNoticeStyled>
        <MessageStyled>{message}</MessageStyled>
        For security purposes, we have updated your password. Please log into
        the app using the updated credentials below.
      </ResetPasswordNoticeStyled>
    );
  };

  const displayCredentials = (): React.ReactNode => {
    setDisplayViewAnalytics({
      view: 'displayCredentials',
      page: 'generateLoginDetailsPage',
    });
    return (
      <React.Fragment>
        {!credentials.newUser && resetPasswordNotice()}
        <InputBox label='User ID' input={credentials.email} />
        <InputBox label='Password' input={credentials.password} />
        <CopyToClipBoardButton
          text={`{"userId":"${credentials.email}","password":"${credentials.password}"}`}
          successText={'Click paste in the app to retrieve details'}
        />
      </React.Fragment>
    );
  };

  const taskStarted = (): React.ReactNode => {
    setDisplayViewAnalytics({
      view: 'taskStarted',
      page: 'generateLoginDetailsPage',
    });
    return (
      <React.Fragment>
        <InputBox label='User ID' input={credentials.email} />
        <InputBox label='Password' input={credentials.password} />
        <CopyToClipBoardButton
          text={`{"userId":"${credentials.email}","password":"${credentials.password}"}`}
          successText={'Click paste in the app to retrieve details'}
        />
        <MessageStyled>
          Looks like you have a study in progress. Login to Vision project app
          with the following credentials to complete the study.
        </MessageStyled>
      </React.Fragment>
    );
  };

  const generateCredentialsButton = (): React.ReactNode => {
    return (
      <React.Fragment>
        <p>
          You do not need an account to take part in this trial. Click the
          button below to generate a temporary User ID and password.
        </p>
        <Button
          clickHandler={onGenerateAccessCode}
          text={
            isGeneratedClicked ? (
              <Loading text='Please wait' />
            ) : (
              'Generate login details'
            )
          }
          type={ButtonType.primary}
        />
      </React.Fragment>
    );
  };

  const displayCreateCredentailsView = (): React.ReactNode => {
    switch (view) {
      case View.displayCredentials:
        return displayCredentials();
      case View.taskStarted:
        return taskStarted();
      default:
        return generateCredentialsButton();
    }
  };

  const displaySteps = (): React.ReactNode => {
    setDisplayPageAnalytics({ page: 'generateLoginDetailsPage' });

    return (
      <React.Fragment>
        <h2>Get Started</h2>
        <hr />
        <h3>Step 1: Create Login Details</h3>
        <AccessCodeContainer>
          {displayCreateCredentailsView()}
          <NoteStyled>Note: This code is for one-time use only.</NoteStyled>
        </AccessCodeContainer>
        <hr />
        <h3>Step 2: Download the App</h3>
        <p>
          Experience our innovative eye-tracking technology for yourself. To
          complete the trial, simply download the app and enter the access code
          details above.
        </p>
        <p>We hope you enjoy the experience!</p>
        <br />
        <DownloadIOSApp />
        <hr />
        <ul>
          {FAQContent.map((item) => {
            return (
              <li key={uuid()}>
                <FaqItem key={uuid()} item={item} />
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {view == View.error ? (
        <ErrorView
          error={error}
          clickHandler={() => setView(View.generateCode)}
        />
      ) : (
        <>
          <Panel>{displaySteps()}</Panel>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};
