import React from 'react';
import Logo from '../assets/images/vision-project.png';
import styled from '@emotion/styled';

const LogoStyled = styled.img`
  display: block;
  margin: 0px auto 36px auto;
  height: 156px;
  width: auto;

  @media only screen and (max-width: 600px) {
    height: 100px;
  }
`;
export const SiteLogo: React.FunctionComponent = () => {
  return <LogoStyled src={Logo} />;
};
