import styled from '@emotion/styled';
import React from 'react';
import { ReactProps } from '../core/interfaces';
import { AnimatedRobot } from '../components/animatedRobot';
import { SiteLogo } from '../components/siteLogo';

const PanelStyled = styled.div`
  margin: 200px auto 20px auto;
  width: 600px;
  color: #212121;
  background-color: white;
  padding: 36px 55px;
  border-radius: 30px;
  height: 70%;
  min-height: 70%;

  @media only screen and (max-width: 600px) {
    width: auto;
    margin: 150px 20px 20px 20px;
    padding: 36px 29px 54px 29px;
  }
`;

export const Panel: React.FunctionComponent<ReactProps> = ({
  children,
}: ReactProps) => {
  return (
    <React.Fragment>
      <AnimatedRobot />
      <PanelStyled>
        <SiteLogo />
        {children}
      </PanelStyled>
    </React.Fragment>
  );
};
