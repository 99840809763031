import { css, Global } from '@emotion/core';
import React from 'react';

import webfont from '../assets/fonts/alrightsans-ultra-webfont.woff';

export const GlobalStyles: React.FunctionComponent = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'alright_sansultra';
          src: url('${webfont}') format('woff2'),
            url('alrightsans-ultra-webfont.woff') format('woff');
          font-weight: normal;
          font-style: normal;
        }

        * {
          font-family: 'Roboto', helvetica, arial, sans-serif;
          margin: 0;
          font-weight: normal;
        }

        body {
          background: linear-gradient(
              180deg,
              #febb2f 0px,
              #fe812c 80px,
              #fe3928 180px
            ),
            #c4c4c4;
        }

        h1 {
          font-weight: 500;
          font-size: 56px;
          text-align: center;
          color: #ff3926;
          font-weight: 500;
        }

        h2 {
          font-weight: 500;
          font-size: 26px;
          text-align: center;
          margin: 20px 0;
        }

        h3 {
          font-size: 20px;
          margin: 20px 0;
        }

        h4 {
          font-weight: 500;
          font-size: 26px;
          line-height: 36px;
          font-size: 26px;
          margin-bottom: 20px;
          font-weight: 700;
        }

        ul {
          list-style: square;
          padding-left: 20px;
        }

        ol {
          list-style: decimal;
          padding-left: 20px;
        }

        ul li,
        ol li {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 20px;
        }

        hr {
          border: none;
          margin: 40px 0;
          border-top: 4px dashed #ccc;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          margin: 5px 0px 5px 0px;
        }

        a {
          color: #ff3926;
          border-bottom: 2px solid #ff3926;
          text-decoration: none;
          cursor: pointer;
        }

        @media only screen and (max-width: 600px) {
          p,
          ul li,
          ol li {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 10px;
            text-align: initial;
          }
        }
      `}
    />
  );
};
