import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { API_GEO_URL } from '../config';
import { IpData } from '../core/interfaces';
import { api } from '../services/apiService';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 0 20px 0px;

  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & > ul > li {
    float: left;
    margin: 0 30px;
    text-align: center;
    vertical-align: middle;
  }

  @media only screen and (max-width: 600px) {
    & > ul > li {
      margin: 0 15px;
    }
  }
`;

const LinkStyled = styled.a`
  color: white;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const ParagraphUSTermsStyled = styled.p`
  text-align: center;
  border-top: 1px solid;
  padding-top: 15px;
  color: white;
  margin: 0px auto 20px auto;
  width: 600px;
  font-weight: 300;
  @media only screen and (max-width: 600px) {
    width: 300px;
  }
`;

const AHrefStyled = styled.a`
  border: none;
  color: white;
  text-decoration: underline;
`;

const USA_COUNTRY_CODE = 'US';
export const Footer: React.FunctionComponent = () => {
  const [isUserLocationUS, setIsUserLocationUs] = useState<boolean>(false);
  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const response = await api<IpData>(API_GEO_URL);
        if (response && response.country_code === USA_COUNTRY_CODE) {
          setIsUserLocationUs(true);
        }
      } catch (error) {
        console.log('unable to fetch user location');
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Container>
        <ul>
          <li>
            <LinkStyled href="/terms-of-use-agreement">Terms of Use</LinkStyled>
          </li>
          <li>
            <LinkStyled href="/privacy-policy">Privacy Policy</LinkStyled>
          </li>
          <li>
            <LinkStyled href="/content-policy">Content Policy</LinkStyled>
          </li>
        </ul>
      </Container>
      {isUserLocationUS && (
        <ParagraphUSTermsStyled>
          If you are a California resident, California law may provide you with
          additional rights regarding our use of your personal information. To
          learn more about your California privacy rights, visit{' '}
          <AHrefStyled
            href="https://playground.xyz/general-privacy-statement-for-california-residents.pdf"
            target="_blank"
          >
            the CCPA Privacy Notice
          </AHrefStyled>
        </ParagraphUSTermsStyled>
      )}
    </React.Fragment>
  );
};
