import { GA4React } from "ga-4-react";
import { GOOGLE_ANALYTIC_MEASUREMENT_ID } from "../config";
// Turn on debug mode to test real time analytics in debug view
const ga4react = new GA4React(GOOGLE_ANALYTIC_MEASUREMENT_ID, { debug_mode: false }).initialize();

interface AnalyticsUserData {
    userExt: string;
    studyId: string;
}

interface AnalyticsPageData {
    page: string;
}

interface AnalyticsViewData {
    view: string;
    page: string;
}

interface AnalyticsClickData {
    action: string;
    page: string;
}

interface AnalyticsErrorData {
    error: string;
    message: string;
    page: string;
}

export const setUserPropertiesAnalytics = (data: AnalyticsUserData) => {
    const { userExt, studyId } = data;
    ga4react
        .then((ga) => {
            ga.gtag("set", "user_properties", {
                user_ext: userExt,
                study_id: studyId,
            });
        })
        .catch((err) => console.error(`Analytics failed: ${err}`));
};

export const setDisplayPageAnalytics = (data: AnalyticsPageData) => {
    const {page} = data;
    ga4react
        .then((ga) => {
            ga.gtag("event", `display_${page}`, {
                "page_name": page,
            });
        })
        .catch((err) => console.error(`Analytics failed: ${err}`))
};

export const setDisplayViewAnalytics = (data: AnalyticsViewData) => {
    const { view, page } = data;
    ga4react
        .then((ga) => {
            ga.gtag("event", `display_${view}`, {
                "page_name": page,
            });
        })
        .catch((err) => console.error(`Analytics failed: ${err}`))
};

export const onClickAnalytics = (data: AnalyticsClickData) => {
    const { action, page } = data;
    ga4react
        .then((ga) => {
            ga.gtag("event", `tap_${action}`, {
                "page_name": page,
            });
        })
        .catch((err) => console.error(`Analytics failed: ${err}`))
};

export const setErrorAnalytics = (data: AnalyticsErrorData) => {
    const { error, message, page } = data;
    ga4react
        .then((ga) => {
            ga.gtag("event", `error_${error}`, {
                "error_message": message,
                "page_name": page,
            });
        })
        .catch((err) => console.error(`Analytics failed: ${err}`))
};