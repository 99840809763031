import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { AnimatedRobot } from '../../components/animatedRobot';
import QRCode from 'qrcode.react';
import { setDisplayPageAnalytics } from '../../services/analyticService';

const DesktopPanelStyled = styled.div`
  color: white;
  font-size: 24px;
  text-align: center;
  margin-top: 400px;
`;

const QRCodeContainer = styled.div`
  background-color: white;
  display: inline-flex;
  align-items: center;
  align-content: ;
  justify-content: center;
  width: 200px;
  height: 200px;
`;

export const DesktopView: React.FunctionComponent = () => {
  useEffect(() => {
    setDisplayPageAnalytics({page: "landingQRCodePage"});
  }, []);
  return (
    <React.Fragment>
      <AnimatedRobot />
      <DesktopPanelStyled>
        <p>
          Please view this website on your iPhone by copying the URL for this
          page and emailing it to yourself.
          <br /> <br />
          Alternatively open your iPhone camera and scan the QR code below to
          open it on your device.
        </p>
        <QRCodeContainer>
          <QRCode value={window.location.href} size={180} />
        </QRCodeContainer>
      </DesktopPanelStyled>
    </React.Fragment>
  );
};
