import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Article, StudyType } from '../core/interfaces';
import { ArticleSection } from '../composites/articleSection';
import { v4 as uuid } from 'uuid';
import { SearchBar } from './searchBar';
import { setDisplayPageAnalytics } from '../services/analyticService';
import { ArticleContainer } from './article';

const HeaderStyled = styled.div`
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
`;

const ArticlesHeader = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin: 32px 0 12px 0px;
`;

interface Props {
  studyType: string;
  articles: Article[];
  isOpenBrowser: boolean;
}

interface CategoryButtonProps {
  category: string;
  selectedCategory: string;
}
const Container = styled.div`
  margin: 0px -29px 16px -29px;
  overflow: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Spacer = styled.div`
  width: 20px;
  display: inline-block;
`;

const CategoryFilter = styled.div((props: CategoryButtonProps) => ({
  padding: '6px 20px',
  display: 'inline-block',
  borderRadius: '15px',
  backgroundColor: `${
    props.category === props.selectedCategory ? '#000000' : '#FFFFFF'
  }`,
  marginLeft: '10px',
}));

const ALL_CATEGORIES = 'All';

export const StudyArticle: React.FunctionComponent<Props> = ({
  studyType,
  isOpenBrowser,
  articles,
}: Props) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    ALL_CATEGORIES
  );
  useEffect(() => {
    setDisplayPageAnalytics({ page: 'articleStudyPage' });
    // The array of categories is not unique, so we need to filter out the duplicates.
    // If no categories are found the array will be empty
    if (articles) {
      const categories = [
        ALL_CATEGORIES,
        ...new Set(
          articles
            .map((item) => item.category)
            .filter((item): item is string => item !== undefined)
        ),
      ];
      setCategories(categories);
    }
  }, []);

  // There is always one category set up in the array, which is `All`
  const hasCategoriesFromFeed = (categories: string[]): boolean => {
    return categories.length > 1;
  };

  const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onClickCategory = (category: string) => {
    setSelectedCategory(category);
  };

  const renderCategories = () => {
    // If there are no categories don't display anything
    if (!hasCategoriesFromFeed(categories)) {
      return <></>;
    }

    return categories.map((category) => {
      return (
        <CategoryFilter
          key={category}
          category={category}
          selectedCategory={selectedCategory}
          onClick={() => onClickCategory(category)}
        >
          <h5
            style={{
              display: 'inline',
              color: `${category === selectedCategory ? 'white' : '#1D1D1F'}`,
            }}
          >
            {capitalizeFirstLetter(category)}
          </h5>
        </CategoryFilter>
      );
    });
  };

  const renderListItems = () => {
    let filteredFeed = articles;

    // If 'All' is not selected, filter the feed. 'All' is the default value, so even when there are no
    // categories in the feed we will pick up all the items in the feed.
    if (selectedCategory !== ALL_CATEGORIES) {
      filteredFeed = articles.filter(
        (item) => item.category === selectedCategory
      );
    }

    return filteredFeed.map((article) => {
      return (
        <ArticleContainer key={uuid()} article={article}></ArticleContainer>
      );
    });
  };

  const displayArticlesOLV = (): React.ReactNode => {
    return (
      <React.Fragment>
        <ArticlesHeader>Trending articles</ArticlesHeader>
        <Container>
          <>
            <Spacer />
            {renderCategories()}
            <Spacer />
          </>
        </Container>
        {renderListItems()}
      </React.Fragment>
    );
  };

  const displayArticlesDisplay = (): React.ReactNode => {
    return (
      <React.Fragment>
        <ArticlesHeader>Display Sites</ArticlesHeader>
        {articles.map((article: Article) => (
          <ArticleSection key={uuid()} article={article}></ArticleSection>
        ))}
      </React.Fragment>
    );
  };

  const displayArticles = (): React.ReactNode => {
    switch (studyType) {
      case StudyType.OLV:
        return displayArticlesOLV();
      case StudyType.Display:
      default:
        return displayArticlesDisplay();
    }
  };
  return (
    <>
      <React.Fragment>
        <HeaderStyled>Browse the web until the timer expires.</HeaderStyled>
        {isOpenBrowser && <SearchBar />}
        {displayArticles()}
      </React.Fragment>
    </>
  );
};
