import React, { useState } from 'react';
import { FAQInfo } from '../../core/interfaces';
import styled from '@emotion/styled';

const DescritpionStyled = styled.div`
  margin: 10px 0px 20px 0px;
`;

interface FaqItemProps {
  item: FAQInfo;
}

export const FaqItem: React.FunctionComponent<FaqItemProps> = (
  props: FaqItemProps
) => {
  const [isItemOpen, setIsItemOpen] = useState(false);

  const handleOnItemOpen = (): void => {
    setIsItemOpen(!isItemOpen);
  };

  return (
    <React.Fragment>
      <a onClick={handleOnItemOpen}>{props.item.title}</a>
      <DescritpionStyled>
        {isItemOpen && props.item.descritpion}
      </DescritpionStyled>
    </React.Fragment>
  );
};
