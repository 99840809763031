import React from 'react';
import styled from '@emotion/styled';

const IFrameStyled = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: white;
`;

interface DocumentContainerProps {
  src: string;
}

export const GoogleDocContainer: React.FunctionComponent<DocumentContainerProps> = (
  props: DocumentContainerProps
) => {
  // reset "responsive" from website. Otherwise, the google drive content
  // does not render correctly
  const viewportmeta = document.querySelector(
    'meta[name=viewport]'
  ) as HTMLInputElement;
  viewportmeta.setAttribute('content', '');
  return <IFrameStyled frameBorder="0" src={props.src} />;
};
