import React from 'react';
import Logo from '../assets/images/vision-project-app.png';
import styled from '@emotion/styled';

const LogoStyled = styled.img`
  display: block;
  margin: 44px auto 32px auto;
  height: 52px;
  width: auto;
`;
export const MobileSiteLogo: React.FunctionComponent = () => {
  return <LogoStyled src={Logo} />;
};
