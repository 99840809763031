import React, { useEffect } from "react";
import styled from "@emotion/styled";
import YoutubeIcon from "../assets/images/youtube-icon.png";
import PortraitIcon from "../assets/images/portrait-icon.png";
import TimeIcon from "../assets/images/time-icon.png";
import {onClickAnalytics, setDisplayPageAnalytics } from '../services/analyticService';

const Panel = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: 16px;
  margin-top: 36px;
  color: #000000;
`;

const Title = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
`;

const TaskTitle = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
`;

const TipsContent = styled.div`
  display: grid;
  margin-top: 16px;
  grid-template-columns: 40px auto;
  & > span {
    padding: 8px 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

const Icon = styled.span`
  display: inline-grid;
  justify-items: center;
  margin-top: 3px;
`;

const LinkStyled = styled.a`
  display: block;
  background-color: white;
  color: #ff3926;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;

  border-radius: 5px;
  padding: 16px;
  margin-top: 30px;
  text-align: center;
`;

const LinkUnderlined = styled.a`
  display: block;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  text-decoration: underline;
  padding: 16px;
  float: center;
`;



export const StudyYoutube: React.FunctionComponent = () => {

  const YoutubeHome: React.FunctionComponent = () => {
    return (
      <>
        <Title>
          Good to go! Watch any YouTube videos until the timer expires.
        </Title>
        <Panel>
          <TaskTitle>During the study</TaskTitle>
          <TipsContent>
            <Icon>
              <img src={YoutubeIcon} />
            </Icon>
            <span>Browse YouTube as you normally would.</span>
            <Icon>
              <img src={PortraitIcon} />
            </Icon>
            <span>
              Browsing session will be anonymous and you will stay within this
              app.
            </span>
            <Icon>
              <img src={TimeIcon} />
            </Icon>
            <span>
              When the time is up, you will be redirected to a completion
              screen.
            </span>
          </TipsContent>
        </Panel>
        <LinkStyled href="/ios/youtube-demo"
          onClick = { () => {
                onClickAnalytics({action: "navigateToYoutubeDemo", page: "youtubeStudyInstructionPage"});
              }
            }
        >
          View a quick demo
        </LinkStyled>
        <LinkUnderlined href="https://m.youtube.com"
          onClick = { () => {
                onClickAnalytics({action: "skipAndContinueToYoutube", page: "youtubeStudyInstructionPage"});
              }
            }
        >
          Skip and start the study
        </LinkUnderlined>
      </>
    );
  };

  useEffect(() => {
      setDisplayPageAnalytics({page: "youtubeStudyInstructionPage"});
  }, []);

  return (
    <>
    <YoutubeHome />
    </>
  );
};
