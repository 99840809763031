import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { routes } from './core/routes';
import { RouteItem } from './core/interfaces';
import { GlobalStyles } from './components/globalStyles';
import { addZendeskWidget } from './core/utils';

const App: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <GlobalStyles />
      <Router>
        <Switch>
          {routes.map((routerItem: RouteItem) => {
            const Component = routerItem.component;
            return (
              <Route
                path={routerItem.path}
                key={routerItem.name}
                render={(props): React.ReactNode => {
                  if (routerItem.zendeskWidget) {
                    addZendeskWidget();
                  }
                  return <Component {...props} />;
                }}
              />
            );
          })}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const app = document.getElementById('app');
ReactDOM.render(<App />, app);
