import React, { useState, useEffect, ReactNode } from 'react';

import styled from '@emotion/styled';
import { MobileSiteLogo } from '../../components/MobileSiteLogo';
import { getFeed } from '../../services/feedService';
import { Article, StudyType } from '../../core/interfaces';
import { Loading } from '../../components/loading';
import { StudyYoutube } from '../../composites/studyYoutube';
import { isYoutubeStudy } from '../../core/utils';
import { StudyArticle } from '../../composites/studyArticle';

const Container = styled.div`
  font-style: normal;
  margin: 0 29px;
  color: white;
`;

const LogoContainer = styled.div`
  padding: 0 5%px;
  margin: 22px 0 7px 0;
`;

const InfoContainer = styled.div`
  margin-top: 80px;
  text-align: center;
`;

enum View {
  error = 'error',
  loading = 'loading',
  articles = 'articles',
}

export const IOSBrowserHome: React.FunctionComponent = () => {
  const [articles, setArticles] = useState([] as Article[]);
  const [view, setView] = useState(View.loading);
  const [studyType, setStudyType] = useState(StudyType.Display);
  const params = new URLSearchParams(window.location.search);
  const isOpenBrowser = params.get('is_open_browser') === 'true';

  const loadingContainer = (): React.ReactNode => {
    return (
      <InfoContainer>
        <Loading />
      </InfoContainer>
    );
  };

  const errorMsg = (): React.ReactNode => {
    return (
      <InfoContainer>
        <h4>Something went wrong please try again later</h4>
      </InfoContainer>
    );
  };

  const getArticles = async (): Promise<void> => {
    const feedId = params.get('id');
    const brand = params.get('brand');
    const studyTypeParam = params.get('studyType');
    const continent = params.get('continent');

    if (!feedId || !brand) {
      setView(View.error);
      return;
    }

    const data = await getFeed(brand, feedId, continent);
    if (data.length === 0) {
      setView(View.error);
      return;
    }
    if (studyTypeParam) {
      setStudyType(studyTypeParam as StudyType);
    }
    setArticles(data);
    setView(View.articles);
  };

  const displayContent = (): React.ReactNode => {
    switch (view) {
      case View.loading:
        return loadingContainer();
      case View.articles:
        const isYoutubeView = isYoutubeStudy(articles);
        if (isYoutubeView) {
          const url = articles[0].url;
          return <StudyYoutube url={url} />;
        }
        return (
          <StudyArticle
            studyType={studyType}
            articles={articles}
            isOpenBrowser={isOpenBrowser}
          />
        );

      case View.error:
      default:
        return errorMsg();
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <Container>
      <LogoContainer>
        <MobileSiteLogo />
      </LogoContainer>
      {displayContent()}
    </Container>
  );
};
