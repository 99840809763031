import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MobileView } from './mobile';
import { DesktopView } from './desktop';
import { getStudyIdFromParams, getUserIdFromParams } from '../../core/utils';
import {
  setUserPropertiesAnalytics,
  setDisplayPageAnalytics,
} from '../../services/analyticService';
import { useLocation } from 'react-router-dom';

export const Landing: React.FunctionComponent = () => {
  const location = useLocation();
  const [userId, setUserId] = useState<string>('');
  const [studyId, setStudyId] = useState<string>('');
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const analytics = useCallback(() => {
    setUserPropertiesAnalytics({ userExt: userId as string, studyId: studyId });
    setDisplayPageAnalytics({ page: 'landingPage' });
  }, [userId, studyId]);

  useEffect(() => {
    let queryStringParams = location.search;
    if (queryStringParams.includes('&amp;')) {
      // sometimes clickworkers url pass a `&amp;`(encoded &) in the invite url,
      // breaking the login page
      queryStringParams = queryStringParams.replace(/&amp;/g, '&');
    }
    const params = new URLSearchParams(queryStringParams);
    setStudyId(getStudyIdFromParams(params));
    setUserId(getUserIdFromParams(params));
  }, [location]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  return (
    <React.Fragment>
      {isMobile ? (
        <MobileView userId={userId} studyId={studyId} />
      ) : (
        <DesktopView />
      )}
    </React.Fragment>
  );
};
