import React, { useState } from 'react';
import styled from '@emotion/styled';

const Container = styled.div``;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 30.5px;
  margin: 10px 0;
`;

const IconContainer = styled.div`
  width: auto;
  padding: 10px 10px 10px 21px;
`;

const InputStyled = styled.input`
  width: calc(100% - 65px);
  background-color: transparent;
  border-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  outline: none;
  ::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #ffffff;
`;

export const SearchBar: React.FunctionComponent = () => {
  const [inputVal, setInputVal] = useState('');

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    const enterKeyCode = 13;
    if (event.keyCode === enterKeyCode) {
      const goToGoogle = `https://www.google.com/search?q=${inputVal}`;
      window.location.href = goToGoogle;
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setInputVal(value);
  };

  return (
    <Container>
      <form
        action="#"
        onSubmit={(e): void => {
          e.preventDefault();
          return;
        }}
      >
        <InputContainer>
          <IconContainer>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                fill="white"
              />
            </svg>
          </IconContainer>
          <InputStyled
            placeholder="Where would you like to start?"
            autoComplete="off"
            type="search"
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            value={inputVal}
          />
        </InputContainer>
      </form>
    </Container>
  );
};
