export const CONTENT_POLICY_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScLj-puru2Nx1qX4pXd5xOoEnqNXRdsSyrdH6LAgiViAU9F2w/viewform';
export const DIRECT_COLLECTION_NOTICE_URL =
  'https://docs.google.com/document/d/e/2PACX-1vTr2FYWlaBmy7YcXlkQ5V8xafvqZVqs4zFHBBUiFsXyFH6nIMkKsjjIym4jCQjCGldmhQ19nLOaZlaf/pub?embedded=true';
export const PRIVACY_POLICY_URL =
  'https://docs.google.com/document/d/e/2PACX-1vRoaS_siLYLyWw1FRWM3sh1IETXoBmqIsU23LtfQf3vOZ_tc0VYfMzv_n6PpSMmlAbtmeLFGF5pzAWQ/pub?embedded=true';
export const TERMS_OF_USE_AGREEMENT_URL =
  'https://docs.google.com/document/d/e/2PACX-1vSaxoYnueViWLKNM-uWxPOrIQh8KfkJrRlAyVrLKvoBwCypbueYNf_-Q5mBSHTWEuTPJruNJlmRHNcP/pub?embedded=true';
export const FUNCTIONS_BASE_URL = process.env['FUNCTIONS_BASE_URL'] || '';
export const API_BASE_URL = process.env['API_BASE_URL'] || '';
const geoApiKey = process.env['API_GEO_KEY'] || '';
export const API_GEO_URL = `https://api.ipdata.co?api-key=${geoApiKey}`;
export const GOOGLE_ANALYTIC_MEASUREMENT_ID = process.env['GOOGLE_ANALYTIC_MEASUREMENT_ID'] || '';
