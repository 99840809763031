import React from 'react';
import styled from '@emotion/styled';

const InputBoxStyled = styled.div`
  margin: auto;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 90%;
  vertical-align: middle;
  width: 90%;
  text-align: unset;
  > p {
    margin: unset;
    line-height: 38px;
    text-align: unset;
  }
`;

const LabelStyled = styled.p`
  margin-bottom: 5px;
  text-align: unset;
`;

interface InputBoxProps {
  label: string;
  input: string;
}

export const InputBox: React.FunctionComponent<InputBoxProps> = ({
  input,
  label,
}: InputBoxProps) => {
  return (
    <React.Fragment>
      <LabelStyled>{label}</LabelStyled>
      <InputBoxStyled>
        <p>{input}</p>
      </InputBoxStyled>
    </React.Fragment>
  );
};
